<template>
    <v-app>
        <v-main>
            <v-container fluid>
                <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-4">
                    <div class="row">
                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>TOTAL UTILISATEURS</h3></v-card-title>
                                <v-card-subtitle>Tous les utilsateurs</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingUserCount == true">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">{{ totalUserCount }}</span>
                                        </v-col>
                                        <v-col><v-img src="../assets/users.svg" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>

                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>JETONS ACHETES</h3></v-card-title>
                                <v-card-subtitle>Tous les jetons</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingTokenPurchased == true">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">
                                                {{ totalTokenPurschasedCount }}
                                            </span>
                                        </v-col>
                                        <v-col><v-img src="../assets/coins.svg" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>UTILISATEURS PREMIUM</h3></v-card-title>
                                <v-card-subtitle>Tous les utilisateurs prémiums</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingUserPremium == true">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">
                                                {{ totalUserPremiumCount }}
                                            </span>
                                        </v-col>
                                        <v-col><v-img src="../assets/premium.svg" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>

                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>JETONS UTILISES</h3></v-card-title>
                                <v-card-subtitle>Total des jetons utilisés</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingTokenUsed == true">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">{{ totalTokenUsedCount }}</span>
                                        </v-col>
                                        <v-col><v-img src="../assets/coins_downgrade.svg" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                    </div><br>

                    <div class="row">

                        <!-- <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>ANDROID </h3></v-card-title>
                                <v-card-subtitle>Utilisateurs utilisant Android</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col><span class="userCount">0</span></v-col>
                                        <v-col><v-img src="../assets/android.png" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>

                        <div class="col-sm">
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>IOS </h3></v-card-title>
                                <v-card-subtitle>Utilisateurs utilisant Ios</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col><span class="userCount">0</span></v-col>
                                    <v-col><v-img src="../assets/ios.png" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div> -->

                        <div class="col-sm">                           
                            <v-card elevation="5" class="rounded-card">
                                <v-card-title><h3>UTILISATEURS CONNECTÉS</h3></v-card-title>
                                <v-card-subtitle>Utilisateurs actuellement connectés</v-card-subtitle>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span v-if="isLoadingUserConnectedCount == true">
                                                <v-progress-circular indeterminate></v-progress-circular>
                                            </span>
                                            <span v-else class="userCount">{{ userConnectedCount }}</span>
                                        </v-col>
                                        <v-col><v-img src="../assets/smart-devices.png" height="50" width="50"></v-img></v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4">
                            <v-card class="mx-auto text-center" color="#ff7102" dark max-width="600">
                                <v-card-text>
                                    <v-sheet color="rgba(0, 0, 0, .12)"><h1 class="lastRegister">{{ lastUserRegisterList.length }}</h1></v-sheet>
                                </v-card-text>
                
                                <v-card-text>
                                    <div class="text-h4 font-weight-thin">Total des utilisateurs inscrits ces derniers 24 heures</div>
                                </v-card-text>
                
                                <v-divider></v-divider>
                
                                <v-card-actions class="justify-center"><v-btn block text @click="showLastUserSign = true"> Voir détail</v-btn></v-card-actions>
                            </v-card>
                        </div>

                        <div class="col-sm-4" v-show="showLastUserSign">
                            <v-card>
                                <v-divider></v-divider>
                                <v-card-title><h3>INSCRITS DERNIER 24H</h3></v-card-title>
                                <v-virtual-scroll v-if="lastUserRegisterList.length > 0" :bench="benched" :items="lastUserRegisterList" height="300" item-height="64">
                                    <template v-slot:default="{ item }">
                                        <v-list>
                                            <v-list-item-group active-class="orange--text">
                                                <v-list-item :key="item.node.userCode">
                                                    <v-list-item-avatar size="32">
                                                        <v-img v-if="item.node.photo == null" src="../assets/userphoto.svg"></v-img>
                                                        <v-img v-else :src="item.node.photo"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title> {{ item.node.name }} </v-list-item-title>
                                                        <v-list-item-subtitle class="text--primary">{{ item.node.firstname }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-btn btn x-small color="secondary" @click="checkInfoUser(item)"><v-icon small>mdi-open-in-new</v-icon> Voir détail</v-btn>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list-item-group><br>
                                        </v-list>
                                        
                                        <v-divider></v-divider>
                                    </template>
                                </v-virtual-scroll>
                                <v-card-text v-else>
                                    <v-row align="center" justify="center">
                                        <span>AUCUN UTILISATEUR INSCRIT</span>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                    </div>
                </v-sheet>

                <v-row>

                    <v-col cols="12">
                        <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-2">
                            <v-card>
                                <v-card-title><h4>LISTE DES UTILSATEURS</h4>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                                </v-card-title>

                                <v-card-text>
                                    <v-data-table :headers="headers" :items="userList" :single-select="singleSelect" show-select :search="search" :loading="loading"
                                        item-key="node.userCode" v-model="selected">
                                        <template v-slot:top>
                                            <v-row>
                                                <v-btn small btn color="secondary" class="ml-2" @click="openDialog()"><v-icon>mdi-bell</v-icon>ALERTER LES COMPTES</v-btn>
                                                <v-btn small btn class="ml-2" color="success">
                                                    <export-excel class="btn" :data="userList" :fields="json_fields" worksheet = "My Worksheet" name = "users.xls">
                                                    <v-icon color="white">mdi-download</v-icon><small class="font-weight-bold text-white">EXPORTER LES DONNEES</small>
                                                    </export-excel>
                                                </v-btn>&nbsp;&nbsp;
                                                <span class="font-weight-bold mt-1">Selectionné:  {{ selected.length }}</span>
                                            </v-row><br><br>
                                        </template><br><br>

                                        <template v-slot:[`item.actions`]="{item}">
                                            
                                            <v-icon title="Voir détail" color="blue" class="mr-3" small @click="checkInfoUser(item)">mdi-pencil-outline</v-icon>
                                            <v-icon title="Supprimer l'utilisateur" color="red" class="mr-3" small @click="deleteUserAccount(item.node.email)">mdi-delete</v-icon>
                                        </template>
                                    </v-data-table>
                                </v-card-text>

                                <v-dialog v-model="dialogUser" width="600" rounded="lg">
                                    <v-card>
                                        <v-toolbar elevation="4">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="orange" @click="dialogNotificationUser = true"><span class="font-weight-bold">Alerter le compte</span></v-btn>
                                        </v-toolbar><br>

                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-avatar class="ms-2" color="white" size="120" variant="flat">
                                                        <v-img v-if="userInfo.photo == null" src="../assets/userphoto.svg"></v-img>
                                                        <v-img v-else :src="userInfo.photo"></v-img>
                                                    </v-avatar>
                                                </v-col>
                                                <v-divider vertical inset></v-divider>

                                                <v-col>
                                                    <v-row>
                                                        <v-col><span class="font-weight-bold">Nom:</span>&nbsp;&nbsp;&nbsp;&nbsp;<small class="text">{{ userInfo.name }}</small></v-col>
                                                        <v-col><span class="font-weight-bold">Prenoms:</span>&nbsp;&nbsp;&nbsp;&nbsp;<small class="text">{{ userInfo.firstname }}</small></v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col><span class="font-weight-bold">Email:</span>&nbsp;&nbsp;&nbsp;&nbsp;<small class="text">{{ userInfo.email }}</small></v-col>
                                                        <v-col><span class="font-weight-bold">Tél:</span>&nbsp;&nbsp;&nbsp;&nbsp;<small class="text">{{ userInfo.phoneNumber }}</small></v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col><span class="font-weight-bold">Jetons acheté:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <small v-if="isLoadingTokenPurchasedByUser == true"><v-progress-circular indeterminate></v-progress-circular></small>
                                                            <small v-else class="text">{{ tokenPurschasedByUser }}</small>
                                                        </v-col>
                                                        <v-col><span class="font-weight-bold">Jetons Utilisés:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <small v-if="isLoadingTokenUsedByUser == true">
                                                                <v-progress-circular indeterminate></v-progress-circular>
                                                            </small>
                                                            <small v-else class="text">{{ tokenUsedByUser }}</small>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="green-darken-1" variant="text" @click="dialogUser = false">Retour</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                            </v-card>
                        </v-sheet>
                    </v-col>
                    <v-divider></v-divider>
                </v-row>

                <!-- <v-row>
                    <v-col cols="12">
                        <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-2">
                            <v-card>
                                <v-card-title><h4>HISTORIQUE DE NOTIFICATIONS</h4>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchNotif" append-icon="mdi-magnify" label="Search"
                                    single-line hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table :headers="headersNotif" :items="notificationList" :search="searchNotif" :loading="loadingNotif">
                                </v-data-table>
                            </v-card>
                        </v-sheet>
                    </v-col>
                </v-row> -->

                <v-row>
                    <v-col cols="12">
                        <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-2">
                            <v-card>
                                <v-card-title><h4>Listes des Utilisateurs Prémiums</h4>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchNotif" append-icon="mdi-magnify" label="Search"
                                    single-line hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table :headers="headersPremium" :items="totalUserPremiumList" :search="searchNotif" :loading="loading">
                                </v-data-table>
                            </v-card>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-dialog v-model="dialogNotificationUser" transition="dialog-top-transition" width="500">
                    <v-card>
                        <v-card-title>Notification à envoyer</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col><v-textarea color="orange" v-model="notificationAlertUser" label="Votre message" dense></v-textarea></v-col>
                            </v-row>
                            <v-row>
                                <v-col><v-text-field color="orange" v-model="linkToNotifyUser" label="Votre lien" placeholder="Domaine uniquement" dense></v-text-field></v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn variant="text" color="orange" @click="alertAccount(notificationAlertUser , linkToNotifyUser , userInfo.userCode)">Envoyer</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                <v-dialog v-model="detailUserDialog" transition="dialog-top-transition" width="1000">
                    <DialogUserVue :user="userInfo"/>
                </v-dialog>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <v-dialog v-model="sendEmailDynamicDialog" transition="dialog-top-transition" width="600">
                    <v-card>
                        <v-card-title>Envoi d'e-mail
                            <v-spacer></v-spacer>
                            <v-btn icon @click="addNewParagraph"><v-icon color="primary">mdi-plus</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col><v-text-field color="orange" v-model="objectDynamic" label="Objet" dense></v-text-field></v-col>
                            </v-row>
                            <v-row>
                                <v-col><v-text-field color="orange" v-model="titleDynamic" label="Titre" dense></v-text-field></v-col>
                            </v-row>
                            <v-row v-for="(paragraph , p) in inputs" :key="p">
                                <v-col cols="10"><v-text-field color="orange" v-model="paragraph.message" :label="'Paragraph n°' + (p + 1)"></v-text-field></v-col>
                                <v-col cols="2"><v-btn icon @click="deleteParagraph(p)"><v-icon color="red">mdi-close</v-icon></v-btn></v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn variant="text" color="orange" @click="sendDynamicEmailToUser(userInfo.email , objectDynamic , titleDynamic , inputs)">Envoyer le courriel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                <v-dialog v-model="dialogNotificationGroupUser" transition="dialog-top-transition" width="500">
                    <v-card>
                        <v-card-title>NOTIFICATION GENERALE</v-card-title>
                        <v-card-text>
                            <v-container>
                                <form>
                                    <div class="form-group">
                                        <label>Type de message:</label>
                                        <v-text-field :error-messages="typeMessageError" dense outlined v-model.trim="$v.typeMessage.$model"></v-text-field>
                                        <small id="emailHelp" class="form-text text-muted">C'est quoi votre message ? </small>
                                    </div>
                                    <div class="form-group">
                                        <label>Votre Message</label>
                                        <v-textarea :error-messages="notificationAlertUserGroupError" outlined dense v-model.trim="$v.notificationAlertUserGroup.$model"></v-textarea>
                                        <small class="form-text text-muted">*Champs obligatoire.</small>
                                    </div>
                                    <div class="form-group">
                                        <label>Champs URL:</label>
                                        <v-text-field :error-messages="linkUserGroupError" v-model.trim="$v.linkToNotifyUserGroup.$model" dense outlined type="text" placeholder="https://"></v-text-field>
                                    </div>
                                    <small id="emailHelp" class="form-text text-muted">NB : (*) devant un champs signifie qu'il est obligatoire.</small>
                                </form>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" small @click="dialogNotificationGroupUser = false"><span>Retour</span></v-btn>
                            <v-btn color="orange" :disabled="btnSendNotificationGroup==true" small @click="checkingAndSendingNotification(notificationAlertUserGroup , typeMessage , linkToNotifyUserGroup)"><span class="text-white">Envoyer la notification</span></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="depositToken" transition="dialog-top-transition" width="500">
                    <v-card>
                        <v-card-title><span>RECHARGER UN UTILISATEUR</span></v-card-title>
                        <v-card-text>
                            <v-container>
                                <form>
                                    <div class="form-group">
                                        <label>Réference du rechargement:</label>
                                        <v-select :items="labelTransaction" item-text="label" item-value="label" type="text" :error-messages="labelDepositError" dense outlined v-model.trim="$v.labelDeposit.$model"></v-select>
                                        <small id="emailHelp" class="form-text text-muted">Saisissez un libellé pour le rechargement? </small>
                                    </div>
                                    <div class="form-group">
                                        <label>Nombre de jetons:</label>
                                        <v-select :items="tokenItems" item-text="amount" item-value="amount" type="number" min="0" :error-messages="tokenToSendError" dense outlined v-model.number="$v.tokenToSend.$model"></v-select>
                                        <small id="emailHelp" class="form-text text-muted">Combien de <strong>ARI</strong> voulez vous envoyer ? </small>
                                    </div>
                                    <small id="emailHelp" class="form-text text-muted">NB : (*) devant un champs signifie qu'il est obligatoire.</small>
                                </form>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn variant="text" color="orange" @click="sendTokenToUser(tokenToSend,labelDeposit,userInfo.userCode)">RECHARGER</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </v-container>
        </v-main>

        <v-alert type="success" class="alert alert-success" dismissible>{{ successMsg }}</v-alert>
        <v-alert type="error" class="alert alert-error" dismissible>{{ errorMsg }}</v-alert>
        <v-alert type="warning" class="alert alert-warning" dismissible>{{ warningMsg }}</v-alert>

        <v-overlay :value="overlay">
            <v-progress-circular color="orange" indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import { numeric, required } from 'vuelidate/lib/validators';
import { baseUrl , userConnect } from '../Config/globalConst';
import DialogUserVue from './dialog_components/DialogUser.vue';
export default {
    name:"TableauBord",
    components:{
        DialogUserVue
    },
    data() {
        return{
            btnSendNotificationGroup:null,
            
            labelDeposit : null,
            tokenToSend : null,
            userCode : null ,

            benched : 0 ,
            depositToken: false ,
            detailUserDialog : false,

            labelTransaction : [
                { label: "Achat de jetons" },
                { label : "Bonus "}
            ],

            tokenItems : [
                { amount : 3},
                { amount : 5},
                { amount : 10}
            ],

            json_fields : {
                'Nom' : 'node.name',
                'Prenom(s)': 'node.firstname',
                'Date d\'inscription': 'node.creationDate',
                'E-mail': 'node.email',
                'Telephone': 'node.phoneNumber',
            },

            overlay: false,
            showLastUserSign : false ,

            errorMsg : null,
            successMsg : null,
            warningMsg : null,
            loading : false,
            loadingNotif: false,
            loadingJetons:false,
            dialogUser : false,

            drawer: false,
            group: null,

            singleSelect: false,
            selected: [],

            dialogNotification : false ,
            dialogNotificationUser : false ,
            dialogNotificationGroupUser : false ,
            sendEmailDynamicDialog: false,

            notificationAlert : null,
            linkToNotify : null,

            notificationAlertUser : null,
            linkToNotifyUser : null,

            notificationAlertUserGroup : null,
            linkToNotifyUserGroup : null,
            typeMessage : null ,

            objectDynamic:null,
            titleDynamic : null,
            paragrapheDynamicList : [],

            inputs : [{ message : null}],

            userConnectedCount : 0,
            
            headers:[
                { text: 'Nom', value: 'node.name' },
                { text: 'Prenoms', value: 'node.firstname' },
                { text: 'Date d\'inscription', value: 'node.creationDate' },
                { text: 'E-mail', value: 'node.email' },
                { text: 'Usercode', value: 'node.userCode' },
                { text: 'N° de Tél', value: 'node.phoneNumber' },
                { text: 'Actions', value: 'actions', sortable:false },
            ],
            
            search: '',

            // headersNotif:[
            //     { text: 'Reference Notif', value: 'node.notifId' },
            //     { text: 'Description', value: 'node.description' },
            //     { text: 'Lien', value: 'node.link' },
            //     { text: 'Date de creation', value: 'node.creationDate' },
            // ],

            headersPremium:[
                { text: 'Noms', value: 'name' },
                { text: 'Prenoms', value: 'firstname' },
                { text: 'N° de Tél', value: 'phoneNumber' },
                { text: "E-mail", value: 'email' },
                { text: "Fin d'abonnement",value: 'subscriptionExpirationDate' },
                // { text: 'Actions', value: 'actions', sortable:false },
            ],
            searchNotif: '',

            headersJetons:[
                { text: 'Nom', value: 'node.user.name' },
                { text: 'Prenoms', value: 'node.user.firstname' },
                { text: 'email', value: 'node.user.email' },
                { text: 'Nbre de jetons', value: 'node.amount' },
                
            ],

            searchJetons: '',

            totalUserCount : null ,
            totalTokenPurschasedCount : null,
            totalTokenUsedCount : null,
            totalUserPremiumCount : null,
            totalUserPremiumList : [],
            userList : [],
            notificationList :[],
            notificationToShow:[],

            jetonList:[],

            lastUserRegisterList : [],
            emailAdmin : null,

            userInfo:{
                userCode : null,
                creationDate: null,
                name: null,
                firstname: null,
                photo:null,
                email:null,
                phoneNumber:null,
                sex:null,
                birthDate:null,
            },

            tokenUsedByUser: null,
            tokenPurschasedByUser: null,
            isLoadingUserPremium : false,
            isLoadingUserPremiumList : false,
            allTokensPurchasedByUser : null ,
            isLoadingUserCount : false,
            isLoadingTokenPurchased : false,
            isLoadingTokenUsed : false,
            isLoadingTokenPurchasedByUser:false,
            isLoadingTokenUsedByUser:false,
            isLoadingUserConnectedCount:false
        }
    },

    validations:{

        notificationAlert :{
            required
        },

        linkToNotify :{
            required
        },

        notificationAlertUserGroup : {
            required
        },

        linkToNotifyUserGroup :{
            required
        },
            
        typeMessage : {
            required
        },

        tokenToSend:{
            required , numeric
        },

        labelDeposit:{
            required
        }

    },

    methods:{

        // VOIR LES INFORMATIONS DETAILLEE DE L'UTILISATEUR
        checkInfoUser(user){
            this.detailUserDialog = true ;
            this.userInfo.firstname = user.node.firstname;
            this.userInfo.creationDate = user.node.creationDate ;
            this.userInfo.name = user.node.name;
            this.userInfo.email = user.node.email
            this.userInfo.userCode = user.node.userCode;
            this.userInfo.photo = user.node.photo;
            this.userInfo.phoneNumber = user.node.phoneNumber
            this.userInfo.sex = user.node.sex
            this.userInfo.birthDate = user.node.birthDate
        },

        openDialogToken(user){
            this.depositToken = true;
            this.userInfo.firstname = user.node.firstname ;
            this.userInfo.creationDate = user.node.creationDate ;
            this.userInfo.name = user.node.name;
            this.userInfo.userCode = user.node.userCode;
        },

        closeDialogToken(){
            this.depositToken = false
        },

        async sendTokenToUser(amount , label , userCode){
            this.$v.$touch();
            if(this.$v.labelDeposit.$invalid){
                this.errorMsg = "Selectionnez un label"
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }else if(this.$v.tokenToSend.$invalid){
                this.errorMsg = "Le champ de jetons est obligatoire"
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000)
            }else{
                this.$swal({
                    title: "Envoyer les jetons?", text: "Êtes vous sure ? Attention cette action est irréversible",
                    type: "Warning", showCancelButton: true,
                    confirmButtonColor: "#c0392b",confirmButtonText: "Oui, supprimer!"
                }).then((result) => {
                    this.overlay = true;
                    if (result.value) {
                        let graphqlQuery = {
                            query : `mutation{
                                payTokens(amount:${amount}, label:"${label}", userCode:"${userCode}"){
                                    success
                                }
                            }`
                        }
                        axios.post(baseUrl, graphqlQuery).then((response) =>{
                            if(response.data.data.payTokens.success === true){
                                this.$swal.fire('Rechargement Reussi' , "L' utilisateur a été rechargé"  , 'success')
                            }else{
                                this.$swal.fire('Rechargement echouée' , "Problème Interne" , 'error')
                            }
                        }).catch((e)=>{
                            this.$swal.fire('Rechargement echouée' , e , 'error')
                        }).finally(()=>{
                            this.overlay = false
                        })
                    }
                })
                this.closeDialogToken()
            }
        },

        //ENVOIE D'EMAIL DYNAMIQUE
        async sendDynamicEmailToUser(usermail , sujet , titre){
            this.sendEmailDynamicDialog = false
            this.overlay = true
            this.inputs.forEach(element => {
                this.paragrapheDynamicList.push(element.message);
            });
            let graphqlQuery = {
                query : `mutation{
                    sendDynamicMail(email: "${usermail}" , sujet:"${sujet}" , titre:"${titre}" , paragraphes:${JSON.stringify(this.paragrapheDynamicList)}){
                        success
                    }
                }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{ 
                if (response.data.data.sendDynamicMail.success === true) {
                this.$swal.fire("Envoi de mail", "E-mail envoyé avec succes","success");
                }
            }).catch((e)=>{
                this.$swal.fire("Echec de l'envoi", e ,"error");
            }).finally(()=>{
                this.overlay = false ;
            })
        },

        sendEmailDialog(usermail){
            this.sendEmailDynamicDialog = true;
            this.userInfo.email = usermail
        },
    
        // AJOUTER UN PARAGRAPHE
        addNewParagraph(){
            this.inputs.push({message: null,})
        },

        deleteParagraph(position){
            this.inputs.splice(position , 1);
        },

        // TO GET COUNT OF USERS VIA API
        async getTotalUserCount(){
            this.isLoadingUserCount = true
            let graphqlQuery = {
                "query" : "query {userCount}"
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.totalUserCount = response.data.data.userCount;
                }
                else{
                    this.errorMsg = "Erreur serveur , actualisez"
                    $('.alert-error').fadeIn();
                    setTimeout(() => {
                        $('.alert-error').fadeOut();
                    }, 4000);
                    this.totalUserCount = 0;
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.isLoadingUserCount = false
            })
        },
        
        // SUPPRIMER UN COMPTE UTILISATEUR
        async deleteUserAccount(email){
            this.$swal({
                title: "Supprimer cet utilisateur?", text: "Êtes vous sure ? Attention cette action est irréversible",
                type: "Warning", showCancelButton: true,
                confirmButtonColor: "#c0392b",confirmButtonText: "Oui, supprimer!"
            }).then((result) => {
                if (result.value) {
                    let graphqlQuery = {
                        query : `mutation{
                            deleteUserByEmail(email: "${email}"){
                                success
                            }
                        }`
                    }
                    axios.post(baseUrl, graphqlQuery).then((response) =>{
                        if(response.data.data.deleteUserByEmail.success === true){
                            this.$swal.fire('Suppression effectuée' , "L'utilisateur supprimée avec succes" , 'success')
                            this.getUsersList();
                            this.getTotalUserCount();
                        }else{
                            this.$swal.fire('Suppression non effectuée' , "Echec de la suppression" , 'error')
                        }
                    })
                }
            })
        },

        returnDateFormatted(dateFormatted){
            let date = new Date(dateFormatted);
            let jour = date.getDate();
            let mois = date.getMonth() + 1 ;
            let annee = date.getFullYear();
            jour = String(jour).padStart(2 , '0');
            mois = String(mois).padStart(2 , '0');
            let dateFormat = `${annee}-${mois}-${jour}`;
            return dateFormat;
        },

        returnHoursMinutes(dateFormatted){
            let date = new Date(dateFormatted);
            let heure = date.getHours();
            let minute = date.getMinutes();
            heure = String(heure).padStart(2, '0');
            minute = String(minute).padStart(2, '0');
            let hoursFormat = `${heure}-${minute}`;
            return hoursFormat;
        },

        // TO GET ALL PURCHASED TOKEN
        async getTotalTokenPurchased(){
            this.isLoadingTokenPurchased = true
            let graphqlQuery = {
                "query" : "query {totalTokensPurchased}"
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.totalTokenPurschasedCount = response.data.data.totalTokensPurchased;
                }
                else{
                    this.errorMsg = "Erreur serveur , actualisez"
                    $('.alert-error').fadeIn();
                    setTimeout(() => {
                        $('.alert-error').fadeOut();
                    }, 4000);
                    this.totalTokenPurschasedCount = 0;
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.isLoadingTokenPurchased = false
            })
        },

        // Query pour les utilisateurs Prémiums
        async userPremium(){
            this.isLoadingUserPremium = true;
            let graphqlQuery = {
                "query" : "query{userPremiumCount}"
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.totalUserPremiumCount = response.data.data.userPremiumCount;
                    console.log('User Premium Count : ', response.data.data.userPremiumCount);
                }
                else{
                    console.log('Error User Premium Count : ', response.data.data.errors);
                    this.errorMsg = response.data.data.errors;
                    $('.alert-error').fadeIn();
                    setTimeout(() => {
                    $('.alert-error').fadeOut();
                    }, 4000);
                    this.isLoadingUserPremium = false;
                }
            }).catch((e)=>{
                console.log('Error User Premium Count : ', e);
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.isLoadingUserPremium = false
            })
        },

        // Query pour la liste des utilisateurs prémiums
        async userPremiumList(){
            this.loading=true;
            let graphqlQuery = {
                "query" : "query{userPremium{name,phoneNumber,firstname,subscriptionExpirationDate,email}}"
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.totalUserPremiumList = response.data.data.userPremium;
                    console.log('User Premium List : ', response.data.data.userPremium);
                }
                else{
                    console.log('Error User Premium List : ', response.data.data.errors);
                    this.errorMsg = response.data.data.errors;
                    $('.alert-error').fadeIn();
                    setTimeout(() => {
                    $('.alert-error').fadeOut();
                    }, 4000);
                    this.isLoadingUserPremiumList = false;
                }
            }).catch((e)=>{
                console.log('Error User Premium List : ', e);
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.isLoadingUserPremiumList = false
            })
        },
        // TO GET ALL TOKEN USED
        async getTotalTokenUsed(){
            this.isLoadingTokenUsed = true;
            let graphqlQuery = {
                "query" : "query{totalTokenUsed}"
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.totalTokenUsedCount = response.data.data.totalTokenUsed;
                }
                else{
                    this.errorMsg = "Erreur de chargement..."
                    $('.alert-error').fadeIn();
                    setTimeout(() => {
                        $('.alert-error').fadeOut();
                    }, 4000);
                    this.totalTokenUsedCount = 0;
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.isLoadingTokenUsed = false;
            })
        },

        // TO GET ALL USERS VIA API
        async getUsersList(){
            this.loading = true
            let graphqlQuery = {
                "query" : "query{allUserProfil{edges{node{ userCode name firstname creationDate email photo phoneNumber birthDate sex } } } }"
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                let date  = new Date();
                let myFinalDate =  date.toISOString().split('T')[0]
                if(response.status == 200){
                    response.data.data.allUserProfil.edges.forEach(element => {
                        element.node.creationDate = this.returnDateFormatted(element.node.creationDate);
                        this.userList.push(element)
                    });
                    this.userList.forEach(element => {
                        if (this.returnDateFormatted(element.node.creationDate) === myFinalDate) {
                            this.lastUserRegisterList.push(element);
                        }
                    });
                }
                else{
                    this.userList = [];
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.loading = false
            })
        },

        // TO GET ALL NOTIFICATIONS VIA API
        async getAllNotifications(){
            this.loadingNotif = true
            let graphqlQuery = {
                "query" : "query{allStaticNotifications{ edges { node{ notifId description link creationDate } } } }"
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.notificationList = response.data.data.allStaticNotifications.edges;
                }
                else{
                    this.notificationList = [];
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.loadingNotif = false
            })
        },

        //CHECK DOUBLONS
        async editUserProfil(user , userCode){
            this.userInfo.firstname = user.node.firstname ;
            this.userInfo.creationDate = user.node.creationDate ;
            this.userInfo.name = user.node.name;
            this.userInfo.userCode = user.node.userCode;
            this.userInfo.email = user.node.email;
            this.userInfo.photo = user.node.photo;
            this.userInfo.phoneNumber = user.node.phoneNumber;
            this.getTotalTokenPurschasedByUser(userCode);
            this.getTotalTokenUsedByUser(userCode);
            this.dialogUser = true;
        },

        // TO GET  TOTAL TOKEN BY USER
        async getTotalTokenPurschasedByUser(userCode){
            this.isLoadingTokenPurchasedByUser = true
            let graphqlQuery = {
                "query" : `query { totalTokensPurchasedByUser(userCode: "${userCode}") }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.tokenPurschasedByUser = response.data.data.totalTokensPurchasedByUser;
                }
                else{
                    this.tokenPurschasedByUser = 0;
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.isLoadingTokenPurchasedByUser = false
            })
        },

        //TO GET TOTAL TOKEN USED BY USER
        async getTotalTokenUsedByUser(userCode){
            this.isLoadingTokenUsedByUser = true
            let graphqlQuery = {
                "query" : `query { totalTokenUsedByUser(userCode: "${userCode}") }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.tokenUsedByUser = response.data.data.totalTokenUsedByUser;
                }
                else{
                    this.tokenUsedByUser = 0;
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.isLoadingTokenUsedByUser = false
            })
        },

        //LOGOUT SESSION
        logout(){
            this.$router.replace({name:"home"})
        },

        close(){
            this.notificationAlert = null;
            this.linkToNotify = null;
            this.dialogNotification = false
        },

    
        // CREATE NOTIFICATION FOR USER
        async createNotification(description, link , userCode){
            let graphqlQuery = {
                query : `mutation{
                    sendStaticNotification(description: "${description}",  link: "https://${link}" , userCode: "${userCode}"){
                        success
                    }
                }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.data.data.sendStaticNotification.success === true){
                this.$swal.fire('Notification enregistré' , 'Opération effectuée avec succes' , 'success')
                this.getAllNotifications();
                }else{
                this.$swal.fire('Echec de l\'enregistrement de notification' , '' , 'error')
                }
            }).catch((e)=>{
                this.$swal.fire('Echec de l\'enregistrement de notification' , e , 'error')
            })
        },

        //ENVOYER UNE NOTIFICATION A TOUS LES UTILISATEURS
        async sendNotificationToUsers(description, link) {
  console.log('Début de la méthode sendNotificationToUsers');
  this.$v.$touch();
  console.log('Validation des données :', this.$v);

  if (this.$v.notificationAlert.$invalid || this.$v.linkToNotify.$invalid) {
    console.log('Les champs obligatoires sont invalides');
    this.errorMsg = "Certains champs omis sont requis";
    $('.alert-error').fadeIn();
    setTimeout(() => {
      $('.alert-error').fadeOut();
    }, 4000);
  } else {
    console.log('Tous les champs validés');
    let graphqlQuery = {
      query: `mutation{
        sendNotificationToAllUsers(cat: "Systeme" , body: "${description}", title: "Mise à jour" , link: "${link}"){
          success
        }
      }`
    };
    console.log('Requête GraphQL:', graphqlQuery.query);
     
    try {
      await axios.post(baseUrl, graphqlQuery).then((response) => {
        console.log('Réponse reçue:', response.data);
        if (response.status === 200 && response.data.data.sendNotificationToAllUsers.success === true) {
          console.log('Notification envoyée avec succès');
          this.createNotification(description, link);
          this.close();
        } else {
          console.log('Erreur lors de l\'envoi de la notification');
          throw new Error('Échec de l\'envoi de la notification');
        }
      }).catch((error) => {
        console.error('Erreur lors de l\'envoi de la notification:', error);
        this.$swal.fire('Notification non envoyée', error.message || 'Une erreur s\'est produite', 'error');
      });
    } catch (error) {
      console.error('Erreur générale:', error);
      this.$swal.fire('Erreur', error.message || 'Une erreur s\'est produite lors de l\'envoi de la notification', 'error');
    }
  }
}
,

        // ENVOI DE MAIL À TOUS LES UTILISATEURS SANS INFORMATIONS PERSONNELLE
        async sendMailToAllUsersWithoutInformation(){
            this.overlay = true
            let graphqlQuery = {
                query: `mutation{ sendEmailToUsersNull{
                    success
                    }
                }`
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if (response.data.data.sendEmailToUsersNull.success === true) {
                    this.$swal.fire('Mail envoyée' , "Les utilisateurs ont bien recu leur mail" , 'success')
                }
            }).catch((e)=>{
                this.$swal.fire('Mail non envoyee' , e , 'error')
            }).finally(()=>{
                this.overlay = false
            })
        },

        openDialog(){
            if(this.selected.length == 0){
                this.$swal.fire('Aucun compte selectionné' , '' , 'error')
            }else{
                this.dialogNotificationGroupUser = true
            }
        },
        
        checkingAndSendingNotification(description, title,  link){
            this.$v.$touch()
            if(this.$v.notificationAlertUserGroup.$invalid){
                this.errorMsg = "La notification doit contenir un message !"
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }else if(this.$v.typeMessage.$invalid){
                this.errorMsg = "Définissez le type de votre message"
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }else{
                if(this.linkToNotifyUserGroup != null){
                    if(this.linkToNotifyUserGroup.includes("https://")){
                        this.$swal.fire('Lien corrompu' , 'Entrer seulement le domaine', 'error')
                    }else{
                        this.sendNotificationToUsersChoosed(description, title,  link);
                    }
                }else{
                    this.sendNotificationToUsersChoosed(description, title,  link);
                }
            }
        },
        // PERSONNALISATION DES NOTIFICATIONS (SEULEMENT AUX UTILISATEURS SELECTIONNES)
        async sendNotificationToUsersChoosed(description, title,  link){
            this.btnSendNotificationGroup = true
            let successList = [];
            let failureList = [];
            this.selected.forEach(element => {
                let graphqlQuery = {
                    query: `mutation{ sendNotificationToUser(cat : "Systeme" , body:"${description}", title:"${title}" ,  link: "https://${link}" , userCode:"${element.node.userCode}"){
                            success
                        }
                    }`
                }
                axios.post(baseUrl, graphqlQuery).then((response) =>{
                        if (response.data.data.sendNotificationToUser.success === true) {
                            successList.push(element)
                        }else{
                            failureList.push(element)
                        }


                        if (successList.length == this.selected.length) {
                            this.$swal.fire('Notification envoyée' , 'Les utilisateurs ont recu leur notification' , 'success'); 
                            setTimeout(() => {   
                                this.getAllNotifications()
                            }, 1000);
                        }

                        if(failureList.length !=0){
                            this.warningMsg = "Certains utilisateurs n'ont pas reçu votre notification !"
                            $('.alert-error').fadeIn();
                            setTimeout(() => {
                                $('.alert-error').fadeOut();
                            }, 4000);
                        }
                    }).catch((e)=>{
                        this.$swal.fire('Notification non envoyee' , e , 'error')
                    }).finally(()=>{
                        this.btnSendNotificationGroup=false;
                    });
                });
            this.dialogNotificationGroupUser = false ;
            this.notificationAlertUserGroup = null
            this.typeMessage = null
            this.linkToNotifyUserGroup = null    
        },


        // RECUPERER LA LISTE DE TOUS LES UTILISATEURS CONNECTÉS
        async findAllUsersConnected(){
            this.isLoadingUserConnectedCount = true
            let graphqlQuery = {
                query : userConnect("user_id")
            }
            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.status == 200){
                    this.userConnectedCount = response.data.data.userConnect.length
                }
                else{
                    this.userConnectedCount= 0
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.isLoadingUserConnectedCount = false
            })
        },

    },

    computed:{
        //CONTRAINTES POUR LES CHAMPS DE SAISIE DE NOTIFICATION
        notificationError(){
            if (!this.$v.notificationAlert.$dirty) return [];
            const errors =  [] ;
            !this.$v.notificationAlert.required && errors.push("Le champs notification est obligatoire");
            return errors ;
        },

        linkError(){
            if (!this.$v.linkToNotify.$dirty) return [];
            const errors =  [] ;
            !this.$v.linkToNotify.required && errors.push("Entrer un lien");
            return errors ;
        },
        typeMessageError(){
            if (!this.$v.typeMessage.$dirty) return [];
            const errors =  [] ;
            !this.$v.typeMessage.required && errors.push("Quel est le type de votre message ?");
            return errors ;
        },

        notificationAlertUserGroupError(){
            if (!this.$v.notificationAlertUserGroup.$dirty) return [];
            const errors =  [] ;
            !this.$v.notificationAlertUserGroup.required && errors.push("Le message ne peut être vide !");
            return errors ;
        },

        linkUserGroupError(){
            if (!this.$v.linkToNotifyUserGroup.$dirty) return [];
            const errors =  [] ;
            !this.$v.linkToNotifyUserGroup.required && errors.push("Le lien est obligatoire ");
            return errors ;
        },

        labelDepositError(){
            if (!this.$v.labelDeposit.$dirty) return [];
            const errors =  [] ;
            !this.$v.labelDeposit.required && errors.push("Choisissez une reference !");
            return errors ;
        },

        tokenToSendError(){
            if (!this.$v.tokenToSend.$dirty) return [];
            const errors =  [] ;
            !this.$v.tokenToSend.required && errors.push(" Combien de jetons voulez-vous envoyer ?");
            !this.$v.tokenToSend.numeric && errors.push(" Mauvaise saisie. le token doit être un nombre !");
            return errors ;
        },
    },

    mounted(){
        //this.getAllTokensPurcharsed();
        this.findAllUsersConnected()
        this.getAllNotifications();
        this.getTotalUserCount();
        this.getTotalTokenPurchased();
        this.getTotalTokenUsed();
        this.getUsersList();
        this.userPremium();
        this.userPremiumList();
    }
}
</script>

<style scoped>
.userCount{
    font-weight: bold;
    font-size: 35px;
}

.text{
    color: black;
    font-weight: bold;
    font-size: 15px;
    opacity: 0.5
}

.copyright{
    color:white
}

.alert{
    z-index: 2100;
    width: 20%;
}

.alert-success{
    position: fixed;
    display: none;
    right: 0;
}

.alert-error{
    position: fixed;
    display: none;
    right: 0;
}

.alert-warning{
    position: fixed;
    display: none;
    right: 0;
}

.lastRegister{
    font-size: 60px;
}

.text-white{
    color:white
}

.content-image{
    text-align: center;
    align-content: center;
}

.rounded-card{
    border-radius:30px;
}

</style>
